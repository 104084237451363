import { UseMutationResult, useMutation, queryOptions, keepPreviousData, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { keys as accountKeys } from 'entities/account';
import { keys as demandKeys } from 'entities/demand';
import { keys as projectKeys } from 'entities/project';
import { keys as userOverviewKeys } from 'entities/userOverview';

import { CommonError } from 'shared/api/types';
import { handleRequestError } from 'shared/lib/axios';
import { queryClient } from 'shared/lib/react-query';
import { pathKeys } from 'shared/lib/react-router';

import { addNotification } from 'shared/model/notification';

import {
  positionItemArchive,
  positionItemCreate,
  positionItemGet,
  positionItemUpdate,
  positionListGet,
} from './requests';

import { PositionCreateRequest, PositionDTO, PositionList, PositionListRequest, PositionUpdateRequest } from './types';

export const keys = {
  root: () => ['position'] as const,
  list: () => [...keys.root(), 'list'] as const,
  listWithFilters: (filters: PositionListRequest) => [...keys.root(), 'list', filters] as const,
  item: (id: string) => [...keys.root(), 'item', id] as const,
  create: () => [...keys.root(), 'create'] as const,
  update: (id: string) => [...keys.root(), 'update', id] as const,
  archive: (id: string) => [...keys.root(), 'archive', id] as const,
};

export const positionsService = {
  queryKey: (filters: PositionListRequest) => keys.listWithFilters(filters),

  getCache: (filters: PositionListRequest) =>
    queryClient.getQueryData<PositionList>(positionsService.queryKey(filters)),

  queryOptions: (filters: PositionListRequest) => {
    const positionsKey = positionsService.queryKey(filters);
    return queryOptions({
      queryKey: positionsKey,
      queryFn: async ({ signal }) => {
        const positions = await positionListGet(filters, { signal });
        return positions;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => positionsService.getCache(filters)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(positionsKey)?.dataUpdatedAt,
      placeholderData: keepPreviousData,
    });
  },

  prefetchQuery: async (filters: PositionListRequest) =>
    queryClient.prefetchQuery(positionsService.queryOptions(filters)),
};

export const positionService = {
  queryKey: (id: string) => keys.item(id),

  getCache: (id: string) => queryClient.getQueryData<PositionDTO>(positionService.queryKey(id)),

  setCache: (position: PositionDTO) => queryClient.setQueryData(positionService.queryKey(position.id), position),

  removeCache: (id: string) => queryClient.removeQueries({ queryKey: positionService.queryKey(id) }),

  queryOptions: (id: string, enabled = true) => {
    const positionKey = positionService.queryKey(id);
    return queryOptions({
      queryKey: positionKey,
      queryFn: async ({ signal }) => {
        const position = await positionItemGet(id, { signal });
        return position;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => positionService.getCache(id)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(positionKey)?.dataUpdatedAt,
      enabled,
    });
  },

  prefetchQuery: async (id: string) => queryClient.prefetchQuery(positionService.queryOptions(id)),
};

export const useCreatePositionMutation = (
  accountId: string,
  projectId: string,
): UseMutationResult<PositionDTO, CommonError, PositionCreateRequest> => {
  const navigate = useNavigate();
  const qClient = useQueryClient();

  return useMutation({
    mutationKey: keys.create(),
    mutationFn: positionItemCreate,
    onSuccess: (data) => {
      qClient.invalidateQueries({ queryKey: keys.list() });
      qClient.invalidateQueries({ queryKey: accountKeys.list() });
      qClient.invalidateQueries({ queryKey: projectKeys.list() });

      addNotification({
        type: 'short',
        method: 'success',
        description: 'Position:PositionsPage.notification.successfullyCreated.description',
      });
      positionService.setCache(data);
      navigate(pathKeys.position.root({ accountId, projectId }));
    },
    onError: (error) => {
      if (error.response?.data.code === 'ItemNotFoundException') {
        addNotification({
          type: 'short',
          method: 'error',
          description: 'Position:PositionCreatePage.notification.notFound.description',
        });
        return;
      }
      handleRequestError(error);
    },
  });
};

export const useUpdatePositionMutation = (
  id: string,
  accountId: string,
  projectId: string,
): UseMutationResult<PositionDTO, CommonError, PositionUpdateRequest> => {
  const navigate = useNavigate();
  const qClient = useQueryClient();

  const updateKey = keys.update(id);

  return useMutation({
    mutationKey: updateKey,
    mutationFn: positionItemUpdate,
    onSuccess: (data) => {
      qClient.invalidateQueries({ queryKey: keys.list() });
      qClient.invalidateQueries({ queryKey: accountKeys.list() });
      qClient.invalidateQueries({ queryKey: projectKeys.list() });
      qClient.invalidateQueries({ queryKey: userOverviewKeys.list() });
      qClient.invalidateQueries({ queryKey: demandKeys.parentInfo(id) });

      addNotification({
        type: 'short',
        method: 'success',
        description: 'Position:PositionsPage.notification.successfullyUpdated.description',
      });
      positionService.setCache(data);
      navigate(pathKeys.position.root({ accountId, projectId }));
    },
    onError: (error) => {
      if (error.response?.data.code === 'ItemNotFoundException') {
        addNotification({
          type: 'short',
          method: 'error',
          description: 'Position:PositionUpdatePage.notification.notFound.description',
        });
        return;
      }
      if (error.response?.data.code === 'DateRangeIntersectionException') {
        addNotification({
          type: 'short',
          method: 'error',
          description: 'Position:PositionUpdatePage.notification.dateIntersection.description',
        });
        return;
      }
      handleRequestError(error);
    },
  });
};

export const useArchivePositionMutation = (
  id: string,
  accountId: string,
  projectId: string,
): UseMutationResult<void, CommonError, string> => {
  const navigate = useNavigate();
  const qClient = useQueryClient();

  const archiveKey = keys.archive(id);

  return useMutation({
    mutationKey: archiveKey,
    mutationFn: positionItemArchive,
    onSuccess: () => {
      qClient.invalidateQueries({ queryKey: keys.list() });
      qClient.invalidateQueries({ queryKey: accountKeys.list() });
      qClient.invalidateQueries({ queryKey: projectKeys.list() });

      positionService.removeCache(id);
      addNotification({
        type: 'short',
        method: 'success',
        description: 'Position:PositionsPage.notification.successfullyArchived.description',
      });
      navigate(pathKeys.position.root({ accountId, projectId }));
    },
    onError: (error) => {
      if (error.response?.data.code !== 'PositionHasUnarchivedAssignmentException') {
        handleRequestError(error);
      }
    },
  });
};
