import { StateCreator, create } from 'zustand';
import { DevtoolsOptions, devtools } from 'zustand/middleware';

import { SearchDTO } from 'entities/user';
import { Maybe } from 'shared/types';

type Actions = {
  closeModal: () => void;
  openModal: (groupId: string, groupName: string) => void;
  moveUsers: (users: SearchDTO['foundUsers'], destination: 'toGroup' | 'fromGroup') => void;
  selectFromGroup: (users: SearchDTO['foundUsers']) => void;
  selectFromAll: (users: SearchDTO['foundUsers']) => void;
  resetUsers: () => void;
};

type State = {
  isOpen: boolean;
  groupId: Maybe<string>;
  groupName: Maybe<string>;
  usersMovedToGroup: SearchDTO['foundUsers'];
  selectedFromAll: SearchDTO['foundUsers'];
  usersMovedFromGroup: SearchDTO['foundUsers'];
  selectedFromGroup: SearchDTO['foundUsers'];
};

export type TransferState = Actions & State;

export const createSlice: StateCreator<TransferState, [['zustand/devtools', never]], [], TransferState> = (set) => ({
  isOpen: false,
  groupId: null,
  groupName: null,
  usersMovedToGroup: [],
  selectedFromAll: [],
  usersMovedFromGroup: [],
  selectedFromGroup: [],
  openModal: (groupId, groupName) => set({ isOpen: true, groupId, groupName }, false, 'openModal'),
  closeModal: () => set({ isOpen: false, groupId: null, groupName: null }, false, 'closeModal'),
  moveUsers: (users, destination) =>
    destination === 'toGroup'
      ? set({ usersMovedToGroup: users }, false, 'moveUsersToGroup')
      : set({ usersMovedFromGroup: users }, false, 'moveUsersFromGroup'),
  selectFromAll: (users) => set({ selectedFromAll: users }, false, 'selectFromAll'),
  selectFromGroup: (users) => set({ selectedFromGroup: users }, false, 'selectFromGroup'),
  resetUsers: () =>
    set(
      { usersMovedToGroup: [], usersMovedFromGroup: [], selectedFromAll: [], selectedFromGroup: [] },
      false,
      'resetUsers',
    ),
});

const devtoolsOptions: DevtoolsOptions = { name: 'transferModalStore' };

export const useTransferModalStore = create<TransferState>()(devtools(createSlice, devtoolsOptions));
