import React from 'react';
import { Menu } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import classNames from 'classnames';

import { ReactComponent as ArrowRight } from 'shared/assets/icons/dynamicColor/arrow-right.svg';
import customAntMenuStyles from 'shared/styles/ant-custom-components/menu.module.scss';

import type { Sidebar } from '../../FoldingTable.types';
import styles from './Sidebar.module.scss';

export interface IFoldingTableSidebarProps {
  sidebar: Sidebar;
  onClickSidebarGroup: ({ key }: { key: string }) => void;
}

const FoldingTableSidebar: React.FC<IFoldingTableSidebarProps> = ({ sidebar, onClickSidebarGroup }) => {
  const renderItems = (): ItemType[] => {
    const newData: ItemType[] = sidebar.groups.map((group) => {
      const submenuItems: ItemType[] = group.items.map((item) => {
        return { label: item.name, key: item.id };
      });

      const submenu: ItemType = {
        label: group.name,
        key: group.id,
        children: submenuItems,
        onTitleClick: onClickSidebarGroup,
      };

      return submenu;
    });

    return newData;
  };

  const customExpandIcon = (props: { isOpen?: boolean }): JSX.Element => {
    return (
      <ArrowRight
        className={classNames(styles.navMenu, { [styles.openArrow]: props.isOpen, [styles.closeArrow]: !props.isOpen })}
      />
    );
  };

  return (
    <div className={classNames(styles.rootWrapper, customAntMenuStyles.customMenu)}>
      <h4>{sidebar.title}</h4>
      {sidebar.groups && sidebar.groups.length > 0 && (
        <Menu
          className={styles.menu}
          mode="inline"
          items={renderItems()}
          expandIcon={(props) => customExpandIcon(props)}
          defaultOpenKeys={sidebar.groups.map((group) => group.id)}
          inlineIndent={15}
          selectable={false}
        />
      )}
    </div>
  );
};

export default React.memo(FoldingTableSidebar);
