import { ReactElement } from 'react';
import { isAxiosError } from 'axios';
import { Navigate, useRouteError } from 'react-router-dom';
import { useStore } from 'zustand';

import { hasToken } from 'entities/session';

import { LONG_NOTIFICATION_DURATION } from 'shared/config';
import { pathKeys } from 'shared/lib/react-router';
import { notificationStore } from 'shared/model/notification';
import { Maybe } from 'shared/types';

// TODO: improve
export function BubbleError(): Maybe<ReactElement> {
  const error = useRouteError();
  const { addNotification } = useStore(notificationStore);

  if (isAxiosError(error)) {
    const status = error?.response?.status;
    const isAuthorized = hasToken();

    if (status === 401 && isAuthorized) {
      return <Navigate to={pathKeys.logout()} />;
    }

    if (status === 401) {
      return <Navigate to={pathKeys.login()} />;
    }

    if (status === 403) {
      addNotification({
        key: 'access-denied',
        type: 'critical',
        duration: LONG_NOTIFICATION_DURATION,
        method: 'warning',
        description: 'Exception:AccessDeniedException',
      });
      return <Navigate to={pathKeys.logout()} />;
    }

    if (status === 404) {
      return <Navigate to={pathKeys.page404()} />;
    }
  }
  if (error) throw error;
  return null;
}
