import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './DiscardSelectedUsersOverlay.module.scss';

export const DiscardSelectedUsersOverlay: React.FC<{
  onConfirm: () => void;
  onCancel: () => void;
  visible: boolean;
}> = ({ onCancel, onConfirm, visible }) => {
  const { t } = useTranslation('AccessGroup');

  return (
    <div className={classNames(styles.overlay, { [styles.visible]: visible })}>
      <div className={styles.content}>
        <div>{t('Transfer.discardSelectedUsersOverlay.text')}</div>
        <div className={styles.buttons}>
          <Button type="primary" onClick={onConfirm}>
            {t('Transfer.discardSelectedUsersOverlay.confirmButton.label')}
          </Button>
          <Button onClick={onCancel}>{t('Transfer.discardSelectedUsersOverlay.cancelButton.label')}</Button>
        </div>
      </div>
      <div className={styles.background} />
    </div>
  );
};
